<template>
    <!-- <vueper-slides class="no-shadow" :slide-ratio="0.4" autoplay fade arrows-outside bullets-outside transition-speed="250">
        <vueper-slide v-for="publish in Islamy.slice(0,7)" :key="publish.id" :image="publish.cover" @click="getFeed(publish)" />
    </vueper-slides> -->
    <div class="blog-area" style="padding-top: 2vh !important;">
    <div class="container">
        <div class="row">
            <vueper-slides 
    autoplay
    rtl
    class="no-shadow"
    :visible-slides="4"
    slide-multiple
    :gap="3"
    :slide-ratio="1 / 5"
    :dragging-distance="200"
    :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 1 } }">
    <vueper-slide class="" v-for="publish in Islamy.slice(0,8)" :key="publish.id" :image="publish.cover" @click="getFeed(publish)" />
  </vueper-slides>
        </div>
    </div>
</div>

    <div class="profit-area  pd-bottom-100" style="padding-top: 50px;">
    <div class="container">
    <div class="row justify-content-center">
        <div class="col-lg-7">
            <div class="section-title text-center">
                <h4 class="b-animate-2 title arabicKufi">قسم الاسلاميات</h4>
                <!-- <p class="content arabic-kufi ">استمتع بأكثر من 100 لعبة جديدة ومتنوعة</p> -->
            </div>
        </div>
    </div>
    <ul class="nav nav-tabs profit-tabs-inner style-two mb-4 arabicKufi justify-content-center" id="myTab" role="tablist">
        <li class="nav-item">
            <a class="nav-link active arabic-kufi" id="tab1-tab" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true"> محاضرات</a>
        </li>
        <li class="nav-item">
            <a class="nav-link arabic-kufi" id="tab2-tab" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false">اناشيد </a>
        </li>
        <li class="nav-item">
            <a class="nav-link arabic-kufi" id="tab3-tab" data-bs-toggle="tab" data-bs-target="#tab3" type="button" role="tab" aria-controls="tab3" aria-selected="false">ادعية</a>
        </li>
        <li class="nav-item">
            <a class="nav-link arabic-kufi" id="tab4-tab" data-bs-toggle="tab" data-bs-target="#tab4" type="button" role="tab" aria-controls="tab4" aria-selected="false">اسلاميات</a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
    <!-- <div class="profit-table-inner">
    <div class="table-responsive"> -->
    <!-- production start -->
    <div class="production-area bg-cover  pd-bottom-70">
        <div class="container">
            <div class="row row--grid " style="margin-right: 5px; margin-left: 5px;">
                <div class="col-6 col-sm-4 col-lg-3 col-xl-3 arabicKufi" v-for="publish in Islamy.slice(0,12)" :key="publish.id">
                    <div class="card card_all" >
                        <!-- <h3 class="card__title pointer">{{ publish.name }}</h3> -->
                        <a @click="getFeed(publish)" class="card__cover pointer">
                            <img v-lazy="publish.cover" alt="">
                             <svgVideos />
                        </a>                  
                        <ul class="card__list pointer">
                            <li>{{ publish.name }}</li>
                            <li>{{ publish.createDate.substring(0,10) }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- feature end -->
    <!-- </div>
    </div> -->
    </div>
    <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
    <!-- production start -->
    <div class="production-area bg-cover  pd-bottom-70">
        <div class="container">
            <div class="row row--grid " style="margin-right: 5px; margin-left: 5px;">       
              <div v-for="publish in anashed" :key="publish.id" class="col-6 col-lg-3 col-md-4 col-sm-6 surah mb-3">
                    <div class="card cardMp3 rounded p-2 arabicKufi">
                        <a class="text-decoration rounded card__cover pointer" @click="getSound(publish)">
                          <svgMP3 />
                          <p class="card__list" style="color: rgb(255, 255, 255); font-size: 14px; flex-direction: column;">{{ publish.nameAR }}
                            <small>{{ publish.SingerNameAR }}</small>
                          </p>
                        </a>
                    </div>
                </div>     
            </div>
        </div>
    </div>
    <!-- feature end -->
    </div>
    <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
    <!-- production start -->
    <div class="production-area bg-cover  pd-bottom-70">
        <div class="container">
            <div class="row row--grid " >
                <div v-for="publish in doaa" :key="publish.id" class="col-6 col-lg-3 col-md-4 col-sm-6 surah mb-3">
                    <div class="card cardMp3 rounded p-2 arabicKufi">
                        <a class="text-decoration rounded card__cover pointer" @click="getSound(publish)">
                          <svgMP3 />
                          <p class="card__list" style="color: rgb(255, 255, 255); font-size: 14px; flex-direction: column;">{{ publish.nameAR }}
                            <small>{{ publish.SingerNameAR }}</small>
                          </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- feature end -->
    </div>
    <div class="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab3-tab">
    <!-- production start -->
    <div class="production-area bg-cover  pd-bottom-70">
        <div class="container">
            <div class="row row--grid " >
                <div v-for="publish in Islamic" :key="publish.id" class="col-6 col-lg-3 col-md-4 col-sm-6 surah mb-3">
                    <div class="card cardMp3 rounded p-2 arabicKufi">
                        <a class="text-decoration rounded card__cover pointer" @click="getSound(publish)">
                          <svgMP3 />
                          <p class="card__list" style="color: rgb(255, 255, 255); font-size: 14px; flex-direction: column;">{{ publish.nameAR }}
                            <small>{{ publish.SingerNameAR }}</small>
                          </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- feature end -->
    </div>
    </div>
    </div>
    </div>
    <br><br>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import svgVideos from "@/components/svgData/svgVideos.vue";
import svgMP3 from "@/components/svgData/svgMP3.vue";
export default {
   name: 'Islamyatt',
   components: { 
      VueperSlides, 
      VueperSlide,
      svgVideos,
      svgMP3
    },
    async setup() {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()
        const Islamy = ref([]);
        const anashed = ref([]);
        const doaa = ref([]);
        const Islamic = ref([]);
        try {
            await HTTP.get("BuzzContent.php?catId=1&LIMIT=12").then((res) => {
                Islamy.value = res.data.getPost; 
            });
        } catch (err) {
           console.log(err)
        }
        try {
            await HTTP.get("IslamicContent.php?catId=1&LIMIT=12").then((res) => {
                anashed.value = res.data.getPost; 
            });
        } catch (err) {
           console.log(err)
        }
        try {
            await HTTP.get("IslamicContent.php?catId=2&LIMIT=12").then((res) => {
                doaa.value = res.data.getPost; 
            });
        } catch (err) {
           console.log(err)
        }
        try {
            await HTTP.get("IslamicContent.php?catId=3&LIMIT=12").then((res) => {
                Islamic.value = res.data.getPost; 
            });
        } catch (err) {
           console.log(err)
        }
        const getFeed = (publish) => {
           cookie.setCookie('vid', publish.id)
           cookie.setCookie('cat_id', publish.catogeryId)
            router.push({ name: "Content", params: { id: publish.id } });
        };
        const getSound = (publish) => {
           cookie.setCookie('sound', publish.id)
           cookie.setCookie('cat_audio', publish.catogeryId)
           router.push({ name: "Sound", params: { id: publish.id } });
        };
       
      return {
        Islamy,
        anashed,
        doaa,
        Islamic,
        getFeed,
        getSound
      }
    },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.pd-top-100 {
  padding-top: 30px;
}
.section-title {
  margin-bottom: 30px;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(0deg, #481582 0, #70499D) !important;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 15px 20px rgba(0,0,0,.3),0 15px 20px rgba(0,0,0,.22);
  border: 0;
}
.card__cover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 0px;
}
.card__title {
  font-family: 'Zain_ExtraBold', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 3px;
  transition: 0.5s;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-wrap: break-word;
  text-shadow: 1px 1px 2px #00000082;
}
.card:hover .card__cover::before {
  opacity: 0.7;
}
.card__cover::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.8) 75%, rgba(0, 0, 0, 0.9) 100%);
    opacity: 0.1;
    transition: 0.5s;

}
.card__cover img {
  width: 100%;
position: relative;
z-index: 1;
transition: 0.5s;
height: 100%;
}

.card__cover svg {
  width: 56px;
}
.card__cover svg {
  position: absolute;
  display: block;
  stroke: #fff;
  width: 46px;
  height: auto;
  transition: 0.5s;
  z-index: 3;
  transform: scale(0.8);
  opacity: 0;
}
.card:hover .card__cover svg {
  opacity: 1;
  transform: scale(1);
}
.card__list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 3px;
}
ul {
  margin: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  padding: 0;
  list-style: none;
}
.card__list li {
  font-size: 12px;
  color: #e0e0e0;
  margin-left: 15px;
  white-space: nowrap;
  font-family: 'Zain_ExtraBold', sans-serif;
}

</style>

<style>
.vueperslide {
 white-space:normal;
 background-size:cover;
 flex-shrink:0;
 display:block;
 width:100%;
 position:relative
}
.vueperslide--clone-1 {
 position:absolute;
 top:0;
 bottom:0;
 right:100%
}
.vueperslides--rtl .vueperslide--clone-1 {
 right:auto;
 left:100%
}
.vueperslide[href] {
 -webkit-user-drag:none
}
.vueperslide__image {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 background-size:cover
}
.vueperslide__loader {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 align-items:center;
 justify-content:center
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 height:100%;
 margin:auto
}
.vueperslides--fade .vueperslide {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 opacity:0;
 transition:ease-in-out opacity;
 transition-duration:inherit
}
.vueperslides--fade .vueperslide--active,
.vueperslides--fade .vueperslide--visible {
 z-index:1;
 opacity:1
}
.vueperslides--slide-image-inside .vueperslide {
 overflow:hidden
}
.vueperslides--3d .vueperslide {
 position:absolute;
 z-index:-1;
 height:100%
}
.vueperslides--3d .vueperslide--previous-slide,
.vueperslides--3d .vueperslide--active,
.vueperslides--3d .vueperslide--next-slide {
 z-index:0
}
.vueperslides--3d .vueperslide--active {
 z-index:1
}
.vueperslides--3d .vueperslide[face=front] {
 transform:rotateY(90deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=right] {
 transform:rotateY(90deg) translate(50%);
 transform-origin:100% 0
}
.vueperslides--3d .vueperslide[face=back] {
 transform:rotateY(270deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=left] {
 transform:rotateY(270deg) translate(-50%);
 transform-origin:0 0
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before,
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 content:"";
 position:absolute;
 bottom:100%;
 left:-1em;
 right:-1em;
 height:2em;
 box-shadow:0 0 20px #00000040;
 z-index:2
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 top:100%;
 bottom:auto
}
.vueperslides__arrows {
 color:#fff
}
.vueperslides__arrows--outside {
 color:currentColor
}
.vueperslides__arrow {
 top:50%;
 background-color:transparent;
 border:none;
 opacity:.7
}
.vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrow--next {
 right:auto;
 left:.5em
}
.vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrow--prev {
 left:auto;
 right:.5em
}
.vueperslides__arrow:hover {
 opacity:1
}
.vueperslides__arrows--outside .vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--next {
 right:auto;
 left:-3.5em
}
.vueperslides__arrows--outside .vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--prev {
 left:auto;
 right:-3.5em
}
.vueperslides__paused {
 top:.7em;
 right:.7em;
 opacity:0;
 text-shadow:0 0 3px rgba(0,0,0,.4);
 z-index:1
}
.vueperslides:hover .vueperslides__paused {
 opacity:1
}
.vueperslides__bullets:not(.vueperslides__bullets--outside) {
 color:#481582
}
.vueperslides__bullet {
 margin:1.5em .6em;
 padding:0;
 border:none;
 background:none
}
.vueperslides__bullet .default {
 width:12px;
 height:12px;
 border-radius:12px;
 border:1px solid currentColor;
 background-color:transparent;
 box-shadow:0 0 1px #00000080,0 0 3px #0000004d;
 transition:.4s ease-in-out;
 box-sizing:border-box
}
.vueperslides__bullet .default span {
 display:none
}
.vueperslides__bullet--active .default {
 border-width:6px
}
.vueperslide,
.vueperslide__image {
 background-position:center
}
.vueperslide__video {
 outline:none
}
.vueperslide--no-pointer-events:before {
 content:"";
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 display: flex;
flex: 1 1 auto;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
/* background: #00000096; */
height: 25%;
bottom: 0px;
position: absolute;
width: 100%;
/* border: 2px solid #5375AC; */
font-size: 16px;
color: #fff;
z-index: 999;
border-radius: 10px;
flex-direction: column;
}
.vueperslide__content-wrapper.parallax-fixed-content,
.vueperslide--has-image-inside .vueperslide__content-wrapper,
.vueperslide--has-video .vueperslide__content-wrapper {
 position:absolute;
 z-index:2;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
 pointer-events:none
}
.vueperslides {
 position:relative;
 min-height: 40vh;
}
.vueperslides--fixed-height .vueperslides__inner,
.vueperslides--fixed-height .vueperslides__parallax-wrapper,
.vueperslides--fixed-height .vueperslide {
 height:inherit
}
.vueperslides--fixed-height .vueperslides__parallax-wrapper {
 padding-bottom:0!important
}
.vueperslides--fixed-height.vueperslides--bullets-outside {
 margin-bottom:4em
}
.vueperslides__inner {
 position:relative;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 min-height: 25vh;
}
.vueperslides__parallax-wrapper {
 position:relative;
 overflow:hidden;
 min-height: 30vh;
}
.vueperslides--3d .vueperslides__parallax-wrapper {
 overflow:visible
}
.vueperslides__track {
 position:absolute;
 top:0;
 height:100%;
 left:0;
 right:0;
 overflow:hidden;
 z-index:1;
 border-radius: 12px
}
.vueperslides--parallax .vueperslides__track {
 height:200%;
 transform:translateY(0)
}
.vueperslides--touchable .vueperslides__track {
 cursor:ew-resize;
 cursor:-webkit-grab;
 cursor:grab
}
.vueperslides--touchable .vueperslides__track--mousedown,
.vueperslides--touchable .vueperslides__track--dragging {
 cursor:-webkit-grabbing;
 cursor:grabbing
}
.vueperslides--3d .vueperslides__track {
 overflow:visible;
 perspective:100em
}
.vueperslides__track-inner {
 white-space:nowrap;
 transition:.5s ease-in-out transform;
 height:100%;
 display:flex
}
.vueperslides--no-animation .vueperslides__track-inner {
 transition-duration:0s!important
}
.vueperslides--fade .vueperslides__track-inner {
 white-space:normal;
 transition:none
}
.vueperslides--3d .vueperslides__track-inner {
 transform-style:preserve-3d
}
.vueperslides__track--mousedown .vueperslides__track-inner {
 transition:.25s ease-in-out transform!important
}
.vueperslides__track--dragging .vueperslides__track-inner {
 transition:none
}
.vueperslides__arrow {
 position:absolute;
 font-size:inherit;
 color:inherit;
 text-align:center;
 transform:translateY(-50%);
 transition:.3s ease-in-out;
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 line-height:1
}
.vueperslides__arrow svg {
 vertical-align:middle;
 stroke:currentColor;
 fill:none;
 width:3.5em;
 padding:1em;
 stroke-width:1;
 transition:.3s ease-in-out;
 box-sizing:border-box;
  display: none
}
.vueperslides__arrow svg:hover {
 stroke-width:1.3
}
.vueperslides__paused {
 position:absolute;
 transition:.3s ease-in-out
}
.vueperslides__bullets {
 display:flex;
 justify-content:center;
 position:absolute;
 bottom:0;
 left:0;
 right:0
}
.vueperslides__bullets--outside {
 position:relative
}
.vueperslides__bullets button,
.vueperslides__bullet {
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 display:flex;
 justify-content:center;
 align-items:center;
 color:inherit
}
.vueperslides__bullets button::-moz-focus-inner,
.vueperslides__bullet::-moz-focus-inner {
 border:0
}
.vueperslides__fractions {
 position:absolute;
 top:.8em;
 left:.5em;
 z-index:2;
 padding:.2em 1em;
 border:1px solid rgba(255,255,255,.5);
 border-radius:2em;
 background:rgba(255,255,255,.2);
 color:#fff
}
.vueperslides__progress {
 position:absolute;
 top:0;
 left:0;
 right:0;
 z-index:2;
 height:6px;
 color:#481582
}
.vueperslides__progress>* {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 background:currentColor;
 transition:.3s ease-in-out
}
@media(max-width:768px){
.vueperslides__bullets {
        top: 100%;
}
}

.vueperslides__bullets button,
.vueperslides__bullet {
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 -ms-user-select:none;
     user-select:none;
 outline:none;
 z-index:2;
 display:flex;
 justify-content:center;
 align-items:center;
 color:#481582;
}

.vueperslides {  top: 60px; }
</style>
